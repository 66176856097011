import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {path: '/', name: 'Home', component: () => import('../views/Home.vue'), }
  ,{path: '/layout', name: 'Layout', component: () => import('../views/Layout.vue'), }
  ,{path: '/login', name: 'Login', component: () => import('../views/Login.vue')}
  ,{path: '/katalog-list', name: 'KatalogList', component: () => import('../views/KatalogList.vue')}
  ,{path: '/:id/katalog-detail', name: 'KatalogDetail', component: () => import('../views/KatalogDetail.vue')}
  ,{path: '/profile', name: 'profile', component: () => import('../views/Profile.vue')}
  ,{path: '/edit-profile', name: 'editprofile', component: () => import('../views/ProfileEdit.vue')}
  ,{path: '/android-home', name: 'androidHome', component: () => import('../views/AndroidHome.vue')}
  ,{path: '/register', name: 'register', component: () => import('../views/Register.vue')}
  ,{path: '/pinjaman', name: 'pinjaman', component: () => import('../views/Pinjaman.vue')}
  ,{path: '/history', name: 'history', component: () => import('../views/History.vue')}
  ,{path: '/camera', name: 'camera', component: () => import('../views/Camera.vue')}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
