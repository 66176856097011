<template><router-view /></template>
<script>
export default {
  methods:{
      formatDate (date) {
          if (!date) return null;
          const [year, month, day] = date.split('-')
          return `${day}/${month}/${year}`
      },
      formatNumber (num) {
          var strArr = num+'';
          strArr = strArr.split('.');
          var str = strArr[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
          if (strArr.length > 1) {
              var str2 = strArr[1];
              if (str2.length > 2) str2 = str2.substring(0,2);
              str += ',' + str2;
          }
          return str;
      }
  }
}
</script>
